import React, { ReactElement, useState } from "react";
import classNames from "classnames";
import AuthService from "../../services/AuthService";
import withNoAuth from "../../hocs/withNoAuth";
import logo from "../../assets/yaldo_text_main_color.svg";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";

function LoginScene(): ReactElement {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [errorGlobal, setErrorGlobal] = useState<string | string[] | null>(
    null
  );
  const history = useHistory();
  const redirectTo = new URLSearchParams(useLocation().search).get("path");
  const { addToast } = useToasts();

  const validateAndSendForm = async (e: any) => {
    e.preventDefault();
    setErrorEmail(null);
    setErrorPassword(null);
    setErrorGlobal(null);
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmailLocal = !email.length || !re.test(email);
    let errorPasswordLocal = !password;
    if (errorEmailLocal) {
      setErrorEmail("Gelieve een geldig e-mailadres in te geven.");
    }

    if (errorPasswordLocal) {
      setErrorPassword("Gelieve een geldig wachtwoord in te geven.");
    }
    if (!errorEmailLocal && !errorPasswordLocal) {
      try {
        await AuthService.login(email, password);
        if (redirectTo) {
          history.replace(redirectTo);
        }
      } catch (e: any) {
        setEmail("");
        setPassword("");
        setErrorGlobal(e.message);
        addToast("Inloggen mislukt!", { appearance: "error" });
      }
    }
  };
  return (
    <div className="gradient w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center bg-white p-6 shadow-md rounded xs:w-11/12 lg:w-4/12">
        <img src={logo} alt="Yaldo logo" width="200px" className="mb-6" />
        <form className="w-full" onSubmit={validateAndSendForm}>
          <div className="mb-4 w-full">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="email"
            >
              E-mailadres
            </label>
            <input
              className={classNames(
                "shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-1",
                { "bg-error text-white": errorEmail }
              )}
              id="text"
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="text-error text-xs">{errorEmail}</span>
          </div>
          <div className="mb-6 w-full">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="password"
            >
              Wachtwoord
            </label>
            <input
              className={classNames(
                "shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-1",
                { "bg-error text-white": errorPassword }
              )}
              id="password"
              type="password"
              placeholder="Wachtwoord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="text-error text-xs">{errorPassword}</span>
          </div>
          <div className="flex items-center justify-between mb-4">
            <button
              className="bg-yaldoInput text-white font-bold py-2 px-4 rounded hover:bg-yaldoInputHover"
              type="submit"
              onClick={validateAndSendForm}
            >
              Inloggen
            </button>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-error text-xs">
              {Array.isArray(errorGlobal) && (
                <ul>
                  {errorGlobal?.map((x) => (
                    <li>{x}</li>
                  ))}
                </ul>
              )}
              {!Array.isArray(errorGlobal) && errorGlobal}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withNoAuth(LoginScene);
