import { usersClient } from "./HttpClients";
import User from "../dto/User.dto";

export default class AuthService {
  public static async getUser(id: number): Promise<User> {
    let response = await usersClient.get<User>(`/${id}`);
    let image = response.data;
    return image;
  }
}
