import React, { Dispatch } from "react";
import MasterDataDto from "../dto/MasterData.dto";

export interface MasterData {
  categories: MasterDataDto[];
}

const MasterDataContext =
  React.createContext<
    | {
        state: MasterData;
        dispatch: Dispatch<Action>;
      }
    | undefined
  >(undefined);

type Action =
  | { type: "setCategories"; payload: MasterDataDto[] }
  | { type: "invalidateCategories" };

const masterDataReducer = (state: MasterData, action: Action) => {
  switch (action.type) {
    case "setCategories": {
      const newState = { ...state };
      newState.categories = action.payload;
      return newState;
    }
    case "invalidateCategories": {
      const newState = { ...state };
      newState.categories = [];
      return newState;
    }
  }
};

const MasterDataProvider = ({ children }: any) => {
  const initialState: MasterData = {
    categories: [],
  };
  const [state, dispatch] = React.useReducer(masterDataReducer, initialState);
  // NOTE: We *might* need to memoize this value
  // http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <MasterDataContext.Provider value={value}>
      {children}
    </MasterDataContext.Provider>
  );
};

const useMasterData = () => {
  const context = React.useContext(MasterDataContext);
  if (context === undefined) {
    throw new Error("useMasterData must be used within a MasterDataProvider");
  }
  return context;
};
export { MasterDataProvider, useMasterData };
