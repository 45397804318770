import { authClient } from "./HttpClients";
import jwt from "jwt-decode";
import TokenUser from "../dto/TokenUser.dto";

interface JwtResponse {
  accessToken: string;
}

export default class AuthService {
  public static async login(
    email: string,
    password: string
  ): Promise<TokenUser> {
    let response = await authClient.post<JwtResponse>("/signin", {
      email,
      password,
    });
    let userToken = response.data.accessToken;
    let user: TokenUser = jwt(userToken);
    localStorage.setItem("userToken", userToken);
    return user;
  }
}
