import React, { Dispatch } from "react";
import User from "../dto/User.dto";

const UserContext =
  React.createContext<
    | {
        state: User | null;
        dispatch: Dispatch<Action>;
      }
    | undefined
  >(undefined);

type Action = { type: "signIn"; payload: User } | { type: "signOut" };

const userReducer = (state: User | null, action: Action) => {
  switch (action.type) {
    case "signIn": {
      return action.payload;
    }
    case "signOut": {
      localStorage.removeItem("userToken");
      return null;
    }
  }
};

const UserProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(userReducer, null);
  // NOTE: We *might* need to memoize this value
  // http://kcd.im/optimize-context
  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
export { UserProvider, useUser };
