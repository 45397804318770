import React, { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

interface Props {
  label: string;
  to: string;
}

export default function MenuItem({ label, to }: Props): ReactElement {
  const location = useLocation();
  let currentPath = location.pathname;

  return (
    <div
      className={classNames(
        "w-full mb-6 font-semibold h-10 flex items-center justify-center",
        {
          "bg-yaldoGray text-yaldoBlue rounded-l-3xl ml-3": currentPath === to,
        }
      )}
    >
      <Link
        className={classNames({
          "transform -translate-x-3": currentPath === to,
        })}
        to={to}
      >
        {label}
      </Link>
    </div>
  );
}
