import axios from "axios";

// AUTH CLIENT
const authClient = axios.create({
  baseURL: "/api/auth",
});
authClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // Add bearer token
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
authClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  return Promise.reject(error.response.data);
});

// USERS CLIENT

const usersClient = axios.create({
  baseURL: "/api/users",
  headers: {
    Authorization: localStorage.getItem("userToken")
      ? "Bearer " + localStorage.getItem("userToken")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
usersClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
usersClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

// EVENT CLIENT

const eventClient = axios.create({
  baseURL: "/api/events",
  headers: {
    Authorization: localStorage.getItem("userToken")
      ? "Bearer " + localStorage.getItem("userToken")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
eventClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
eventClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

// PRODUCT CLIENT

const productClient = axios.create({
  baseURL: "/api/products",
  headers: {
    Authorization: localStorage.getItem("userToken")
      ? "Bearer " + localStorage.getItem("userToken")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
productClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
productClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

export { authClient, usersClient, eventClient, productClient };
