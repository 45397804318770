import React, { ReactElement, Suspense } from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import withAuth from "../../hocs/withAuth";
import quote from "../../assets/yaldo_icon_white.svg";
import MenuItem from "../../components/skeleton/MenuItem";
import WaitingScreenScene from "../waitingScreen/WaitingScreenScene";
import UserMenu from "../../components/skeleton/UserMenu";

const EventsScreen = React.lazy(() => import("../events/EventsScreen"));
const CreateNewEventScreen = React.lazy(
  () => import("../events/CreateNewEventScreen")
);

function PageSkeleton(): ReactElement {
  return (
    <div className="flex bg-yaldoGray w-full h-full p-4">
      <div className="w-56 bg-yaldoBlue text-white h-full rounded-3xl">
        <div className="flex justify-center my-8">
          <img className="w-14" src={quote} alt="Yaldo" />
        </div>
        <MenuItem label="Dashboard" to="/" />
        <MenuItem label="Evenementen" to="/events" />
        <MenuItem label="Agenda" to="/calendar" />
        <MenuItem label="Winkel" to="/store" />
        <MenuItem label="Team" to="/team" />
        <MenuItem label="Instellingen" to="/settings" />
      </div>
      <div className="w-full h-full p-3 overflow-y-scroll">
        <Suspense fallback={<WaitingScreenScene secondary />}>
          <div className="flex justify-end items-center">
            <UserMenu />
          </div>
          <Switch>
            <Route path="/events/new">
              <CreateNewEventScreen />
            </Route>
            <Route path="/events">
              <EventsScreen />
            </Route>
            <Route path="/calendar">
              <h1>Agenda</h1>
            </Route>
            <Route path="/store">
              <h1>Winkel</h1>
            </Route>
            <Route path="/team">
              <h1>Team</h1>
            </Route>
            <Route path="/settings">
              <h1>Instellingen</h1>
            </Route>
            <Route path="/loadingInside">
              <WaitingScreenScene secondary />
            </Route>
            <Route path="/">
              <div className="flex justify-between">
                <p>&nbsp;</p>
              </div>
            </Route>
          </Switch>
        </Suspense>
      </div>
    </div>
  );
}
export default withAuth(PageSkeleton);
