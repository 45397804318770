import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../context/UserContext";
import TokenUser from "../dto/TokenUser.dto";
import jwt from "jwt-decode";
import WaitingScreenScene from "../scenes/waitingScreen/WaitingScreenScene";
import UserService from "../services/UserService";

const withNoAuth = (Component: any) => (props: any): ReactElement => {
  const [waiting, setWaiting] = useState<boolean>(true);
  const { state, dispatch } = useUser();
  const history = useHistory();

  useEffect(() => {
    async function fetchAndSetUser(tokenUser: TokenUser) {
      const user = await UserService.getUser(tokenUser.id);
      dispatch({ type: "signIn", payload: user });
    }

    if (state) {
      history.push("/");
    } else {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        const tokenUser: TokenUser = jwt(userToken);
        fetchAndSetUser(tokenUser);
        history.push("/");
      } else {
        setWaiting(false);
      }
    }
  }, [history, state, setWaiting, dispatch]);

  if (waiting) {
    return <WaitingScreenScene />;
  } else {
    return <Component {...props} />;
  }
};

export default withNoAuth;
