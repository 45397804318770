import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import TokenUser from "../dto/TokenUser.dto";
import jwt from "jwt-decode";
import WaitingScreenScene from "../scenes/waitingScreen/WaitingScreenScene";
import UserService from "../services/UserService";

const withAuth =
  (Component: any) =>
  (props: any): ReactElement => {
    const [waiting, setWaiting] = useState<boolean>(true);
    const { state, dispatch } = useUser();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      async function fetchAndSetUser(tokenUser: TokenUser) {
        const user = await UserService.getUser(tokenUser.id);
        dispatch({ type: "signIn", payload: user });
      }

      if (!state) {
        const userToken = localStorage.getItem("userToken");
        if (userToken) {
          const tokenUser: TokenUser = jwt(userToken);
          fetchAndSetUser(tokenUser);
        } else {
          history.push("/login?path=" + location.pathname + location.search);
        }
      } else {
        setWaiting(false);
      }
    }, [history, state, setWaiting, dispatch, location]);

    if (waiting) {
      return <WaitingScreenScene />;
    } else {
      return <Component {...props} />;
    }
  };

export default withAuth;
