import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import React, { ReactElement, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import classNames from "classnames";
import { useUser } from "../../context/UserContext";
export default function UserMenu(): ReactElement {
  const { state, dispatch } = useUser();
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setIsActive(false),
  });
  return (
    <div
      ref={ref}
      className={classNames(
        "relative inline-block p-1 rounded-lg hover:bg-yaldoDarkGray",
        { "bg-yaldoDarkGray": isActive }
      )}
    >
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsActive(!isActive)}
      >
        <img
          src={state?.image}
          alt="profielfoto"
          className="w-12 h-12 rounded-full mr-2"
        />
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      {isActive && (
        <div className="absolute bg-yaldoDarkGray w-40 shadow-lg z-10 right-0">
          <div
            className="w-full hover:bg-yaldoGray cursor-pointer p-2 text-center"
            onClick={() => dispatch({ type: "signOut" })}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
}
