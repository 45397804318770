import React from "react";
import LoginScene from "../src/scenes/login/LoginScene";
import WaitingScreenScene from "../src/scenes/waitingScreen/WaitingScreenScene";
import PageSkeleton from "./scenes/pageSkeleton/PageSkeleton";
import { UserProvider } from "./context/UserContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MasterDataProvider } from "./context/MasterDataContext";
import { ToastProvider } from "react-toast-notifications";

function App() {
  return (
    <ToastProvider>
      <UserProvider>
        <MasterDataProvider>
          <Router>
            <Switch>
              <Route path="/login">
                <LoginScene />
              </Route>
              <Route path="/loading">
                <WaitingScreenScene />
              </Route>
              <Route path="/">
                <PageSkeleton />
              </Route>
            </Switch>
          </Router>
        </MasterDataProvider>
      </UserProvider>
    </ToastProvider>
  );
}

export default App;
